.sec-headline.webnp-subheadline {
	width: -moz-fit-content;
	width: fit-content;
	color: rgba(var(--color08),1);
	background: rgba(var(--color01),1);
	padding: 0.65rem 2.25rem 0.5rem 1rem;
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
}

.np-modal.webnpModal {
	background: rgba(var(--color08),1);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	border-radius: 10px;
	overflow: auto;
}
@media ( min-width: 641px ) {
	.np-modal.webnpModal {
		top: 50%;
	}
}
.np-modal.webnpModal .modalInner {
	height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.np-modal.webnpModal > .modalClose {
	position: absolute;
	bottom: 100%;
	right: 1rem;
	text-align: center;
	cursor: pointer;
	width: 32px;
	height: 32px;
}

.viewer-wrap .viewertext {
	font-size: min(4vw, 20px);
	font-weight: 700;
  display: block;
	text-align: center;
	color: rgba(var(--color01),1);
}


/* select Date
_______________________________________________ */
.webnp-select1 {
	margin: 0.5rem 0 1rem 0;
	width: auto;
}
.webnp-select1 dl {
	display: flex;
	align-items: center;
}
.webnp-select1 dl dd {
	width: 100%;
}
.webnp-select1 dl dd + dd {
  margin-left: 1.5rem;
}
.webnp-select1 dl dd .currentDate {
	font-size: min(4vw, 18px);
	padding: 1rem;
	border: 1px solid rgba(var(--color01),1);
	border-radius: 3px;
	cursor: pointer;
	width: 100%;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.webnp-select1 dl dd .currentDate::after {
	content: '';
	width: 18px;
	height: 18px;
	margin: 0 0 0 2rem;
	display: block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%233471d6"/></svg>');
	transform: rotate(90deg);
}
#npDateWrap {
	padding: 5% 1rem 5% 1rem;
	height: 100%;
	display: block;
}
@media ( min-width: 641px ) {
	.webnp-select1 {
		margin-bottom: 1rem;
		width: -moz-fit-content;
		width: fit-content;
	}
	.webnp-select1 dl dd {
		width: auto;
	}
}

/* CALENDAR
_______________________________________________ */
.calendar-wrap {
	width: -moz-fit-content;
	width: fit-content;
	margin: auto;
	display: block;
	width: 100%;
	max-width: 480px;
}
.next-prev-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}
.next-prev-button button {
  cursor: pointer;
  width: min(12vw, 48px);
	height: min(12vw, 48px);
	display: block;
  outline: none;
  background: none;
  border: none;
  margin: 0 1em;
}
.next-prev-button .prev::before,
.next-prev-button .next::before {
  content: '';
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.prev::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M28.05 37.2 14.8 23.95 28.05 10.7l3.35 3.35-9.9 9.9 9.9 9.9Z" fill="%233471d6"/></svg>');
}
.next::before {
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%233471d6"/></svg>');
}
.currentMonthDay {
  text-align: center;
	white-space: nowrap;
  padding: 0 0.5rem;
	color: rgba(var(--color01),1);
	font-weight: 400;
	font-size: min(5vw, 24px);
}

#calendar {
  width: 100%;
  margin: 0 auto;
}
#calendar table {
  border-collapse: collapse;
  width: 100%;
}

#calendar thead {
  padding: 0.5rem 0;
}
#calendar .dayOfWeek {
  border-bottom: 1px solid rgba(var(--color00),0.5);
}
#calendar .dayOfWeek th {
  text-align: center;
  vertical-align: middle;
  padding: 1em 0;
  font-weight: 700;
}
#calendar .dayOfWeek th:first-child {
  color: rgba(var(--color03),0.9);
}
#calendar .dayOfWeek th:last-child {
  color: rgba(var(--color01),0.8);
}

#calendar tbody {
  padding: 0.5rem 0;
}
#calendar td {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background: rgba(var(--color08),0.9);
	position: relative;
  padding: 2.5vw;
  border-radius: 50%;
}
@media ( min-width: 834px ) {
  #calendar td {
    padding: 2rem;
  }
}
#calendar td::before {
	content: '';
	display: block;
	padding-top: calc( 100% / 1 * 1 );
}
#calendar td > span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: min(4vw, 18px);
  font-weight: 700;
}
#calendar td:first-child,
#calendar td.holiday {
  color: rgba(var(--color03),0.9);
}
#calendar td:last-child {
  color: rgba(var(--color01),0.8);
}
#calendar td.today {
	background: rgba(var(--color01),1);
	color: rgba(var(--color08),1);
}
#calendar td.choiced {
  background: rgba(var(--color05),0.5);
	color: rgba(var(--color08),1);
}
#calendar td.disabled {
  border: none;
  color: rgba(var(--color00),0.2);
  background: transparent;
  cursor: not-allowed;
}
#calendar td.disabled.nonpub {
  color: rgba(var(--color06),0.9);
}
#calendar td.disabled.nonpub span sup {
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 400;
  font-size: 9px;
  position: absolute;
  white-space: nowrap;
}
#calendar td:not(.disabled):hover {
	cursor: pointer;
	background: rgba(var(--color01),1);
	color: rgba(var(--color08),1);
  border-radius: 50%;
  transition: background .5s ease;
}


/* select Paper
_______________________________________________ */
.webnp-select2 {
	margin: 0.5rem 0 1rem 0;
	font-size: min(4vw, 18px);
	border: 1px solid rgba(var(--color01),1);
	border-radius: 3px;
	cursor: pointer;
	width: 100%;
	padding: 1rem;
}
.webnp-select2 ul {
	display: flex;
	justify-content: space-between;
}
.webnp-select2 ul::after {
	content: '';
	width: 18px;
	height: 18px;
	margin: 0 0 0 2rem;
	display: block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%233471d6"/></svg>');
	transform: rotate(90deg);
}
.webnp-select2 ul li {
	display: none;
	font-weight: 700;
}
.webnp-select2 ul li.current {
	display: block;
	pointer-events: none;
}
.webnp-select2 ul li.current a {
  color: rgba(var(--color00),1);
}
.webnp-select2 ul li.currentTitle {
	display: none;
}
.webnp-select2 ul li.currentTitle.current {
	display: block;
}
#npPaperWrap {

}
#npPaperWrap .webnp-select2 {
	border: none;
	cursor: default;
}
#npPaperWrap .webnp-select2 ul {
	display: block;
}
#npPaperWrap .webnp-select2 ul::after {
	display: none;
}
#npPaperWrap .webnp-select2 ul li {
	display: block;
	color: rgba(var(--color00),1);
	padding: 1em 0 1em 0.5em;
	border-bottom: 1px solid rgba(var(--color00),0.1);
}
#npPaperWrap .webnp-select2 ul li.current:not(.currentTitle) {
  background: rgba(var(--color01),1);
}
#npPaperWrap .webnp-select2 ul li.current a {
  color: rgba(var(--color08),1);
}
#npPaperWrap .webnp-select2 ul li a {
  width: 100%;
  display: block;
  color: rgba(var(--color00),1);
}
#npPaperWrap .webnp-select2 ul li.currentTitle {
	color: rgba(var(--color01),1);
	padding-top: 0;
}
@media ( min-width: 641px ) {
	.webnp-select2 {
		margin-bottom: 1rem;
		padding: 0;
		border: none;
		border-radius: none;
		cursor: default;
		width: -moz-fit-content;
		width: fit-content;
	}
	.webnp-select2 ul {
		background: rgba(var(--color08),1)!important;
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		justify-content: flex-start;
		width: 100%;
	}
	.webnp-select2 ul::after {
		display: none;
	}
	.webnp-select2 ul li {
		font-size: min(3.5vw, 14px);
		color: rgba(var(--color01),1);
		background: rgba(var(--color02),1);
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;

		text-align: center;
		cursor: pointer;
		transition: all 0.25s ease;
		pointer-events: auto;
	}
	.webnp-select2 ul li a {
    display: block;
    padding: 0.75em 3em;
		color: rgba(var(--color01),1);
	}
	.webnp-select2 ul li.current a,
	.webnp-select2 ul li:hover a {
		color: rgba(var(--color08),1);
	}
	.webnp-select2 ul li.current,
	.webnp-select2 ul li:hover {
		color: rgba(var(--color08),1);
		background: rgba(var(--color01),1);
	}
}


/* select Page
_______________________________________________ */
.webnp-select3 {
	margin: 0.5rem 0 1rem 0;
	font-size: min(4vw, 18px);
	border: 1px solid rgba(var(--color01),1);
	border-radius: 3px;
	cursor: pointer;
	width: 100%;
	padding: 1rem;
}
.webnp-select3 ul {
	display: flex;
	justify-content: space-between;
}
.webnp-select3 ul::after {
	content: '';
	width: 18px;
	height: 18px;
	margin: 0 0 0 2rem;
	display: block;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m18.75 37.2-3.35-3.35 9.9-9.9-9.9-9.9 3.35-3.35L32 23.95Z" fill="%233471d6"/></svg>');
	transform: rotate(90deg);
}
.webnp-select3 ul li {
	display: none;
}
.webnp-select3 ul li.current {
	display: block;
	pointer-events: none;
}
.webnp-select3 ul li.currentTitle {
	display: none;
}
.webnp-select3 ul li.currentTitle.current {
	display: block;
}
.webnp-select3 ul li span {
	font-weight: 700;
}
.webnp-select3 ul li a {
  font-weight: 700;
	display: block;
  color: rgba(var(--color01),1);
}
.webnp-select3 ul li a[href*="#"] {
	color: rgba(var(--color00),1);
}

#npPageWrap .webnp-select3 {
	border: none;
	cursor: default;
}
#npPageWrap .webnp-select3 ul {
	display: block;
}
#npPageWrap .webnp-select3 ul::after {
	display: none;
}
#npPageWrap .webnp-select3 ul li {
	display: block;
	padding: 1em 0 1em 0.5em;
	border-bottom: 1px solid rgba(var(--color00),0.1);
}
#npPageWrap .webnp-select3 ul li.currentTitle {
	color: rgba(var(--color01),1);
	padding-top: 0;
}
@media ( min-width: 641px ) {
	.webnp-select3 {
		padding: 0;
		border: none;
		border-radius: none;
		cursor: default;
		width: -moz-fit-content;
		width: fit-content;
	}
	.webnp-select3 ul {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		color: rgba(var(--color01),1);
	}
	.webnp-select3 ul::after {
		display: none;
	}
	.webnp-select3 ul li {
		font-size: min(4vw, 16px);
		display: flex;
		align-items: center;
		cursor: pointer;
		margin-bottom: 0.5rem;
	}
	.webnp-select3 ul li span {
		display: inline-block;
		font-weight: 700;
		padding: 0.25rem 0.5rem 0.25rem 0.5em;
	}
	.webnp-select3 ul li.current span {
		background: rgba(var(--color01),1);
		color: rgba(var(--color08),1);
		border-radius: 3px;
	}
	.webnp-select3 ul li:not(:last-child)::after {
		content: '\00ff0f';
		padding: 0.25rem 0.25rem 0.25rem 0.5rem;
		font-weight: 400;
		cursor: default;
	}
}


.current {
	transition: all .5s ease;
}
